<template>
  <div>
    <div class="flix-badge">
      <a class="flix-html-a flix-text-warning" href="#" @click.prevent="open = !open">
        <flixIcon v-if="!open" :id="'plus'" />
        <flixIcon v-else :id="'minus'" />
      </a>
      <span v-if="open" style="position: relative">
        <div class="input">
          <input type="text" v-model='tag' :placeholder="placeholderDefault"/>
        </div>
        <a class="flix-html-a flix-text-warning flix-save" href="#" @click.prevent="addCategory()"><flixIcon :id="'ok'" /> {{ $t('message.save', {name: ''}) }}</a>
      </span>
      <a v-if="open" href="#" class="tag" @click.prevent="tag = placeholder; addCategory()">{{ placeholder }}</a>
    </div>
    <div class="flix-badge" v-for="(badge, badgeID) in services[index].short" :key="badgeID">{{ badge }} <a href="#" @click.prevent="deleteCategory(badgeID)" class="flix-html-a flix-text-danger"><flixIcon :id="'remove'" /></a></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    services: Array, index: Number
  },
  data () {
    return {
      open: false,
      tag: '',
      placeholderDefault: 'Schlagwort',
      placeholder: '',
      allTags: []
    }
  },
  watch: {
    tag (value) { this.tag = value.split(' ').join('_'); this.checkPlaceholder() }
  },
  methods: {
    getPlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.placeholderDefault
    },
    checkPlaceholder () {
      if (!this.tag.trim()) {
        this.placeholder = ''
        return false
      }
      this.allTags.forEach(function (tag) {
        if (tag.indexOf(this.tag) !== -1 && this.services[this.index].short.indexOf(tag) === -1) {
          this.placeholder = tag
          return false
        }
      }.bind(this))
    },
    deleteCategory (index) {
      this.services[this.index].short.splice(index, 1)
      this.$store.dispatch('setChangeService', [this.index, this.services[this.index]])
    },
    addCategory (index) {
      if (!this.tag.trim() || this.services[this.index].short.indexOf(this.tag) !== -1) {
        return false
      }
      if (typeof this.services[this.index].short !== 'object') {
        this.services[this.index].short = []
      }
      this.services[this.index].short.push(this.tag)
      this.$store.dispatch('setChangeService', [this.index, this.services[this.index]])
      this.tag = ''
      this.open = false
      this.getAllTags()
    },
    getAllTags () {
      this.allTags = {}
      this.services.forEach(function (service) {
        if (typeof service.short !== 'object') {
          return true
        }
        service.short.forEach(function (k) {
          this.allTags[k] = 1
        }.bind(this))
      }.bind(this))
      this.allTags = Object.keys(this.allTags)
    }
  },
  mounted () {
    this.getAllTags()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-badge
    display: inline-block
    margin-right: 5px
  .input
    display: inline-block

    z-index: 2
  input
    margin: 0 4px
    height: 12px
    width: 200px
    padding-left: 4px
    &::placeholder
      color: orange
  .tag
    font-weight: normal
    text-align: left
    color: white
    padding-left: 20px
    display: block
    margin-top: 2px
    font-size: 10pt

  @media (max-width: 500px)
    input
      width: 90px
    .flix-save
      font-size: 9pt
    .flix-badge
      display: block
      margin-top: 10px

</style>
